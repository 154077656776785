import React from "react";
import theme from "theme";
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | Ozel Cankurtaran Sürüş Okulu
			</title>
			<meta name={"description"} content={"Kendine Güvenen Sürüş Yolculuğunuz Burada Başlıyor"} />
			<meta property={"og:title"} content={"Ana Sayfa | Ozel Cankurtaran Sürüş Okulu"} />
			<meta property={"og:description"} content={"Kendine Güvenen Sürüş Yolculuğunuz Burada Başlıyor"} />
			<meta property={"og:image"} content={"https://crystallineoasis.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://crystallineoasis.com/img/f_tf-20231211.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://crystallineoasis.com/img/f_tf-20231211.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://crystallineoasis.com/img/f_tf-20231211.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://crystallineoasis.com/img/f_tf-20231211.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://crystallineoasis.com/img/f_tf-20231211.png"} />
			<meta name={"msapplication-TileImage"} content={"https://crystallineoasis.com/img/f_tf-20231211.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" background="#dce5f0" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://crystallineoasis.com/img/1.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Ozel Cankurtaran Sürüş Okulu
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
						Ozel Cankurtaran Sürüş Okulu'nda, araç kullanmayı öğrenmenin önemli bir dönüm noktası olduğunun farkındayız. Amacımız, kendinize güvenen ve güvenli bir sürücü olmanızı sağlayan destekleyici ve kapsamlı bir öğrenme deneyimi sunmaktır. İster yeni başlıyor olun, ister becerilerinizi tazelemek istiyor olun, başarılı olmanıza yardımcı olmak için buradayız
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
							padding="0 0 0 0"
							background="0"
							color="--primary"
						>
							Bize Ulaşın &gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Sürüş Okulu Hakkında
					</Text>
					<Text margin="0px 0px 70px 0px" font="--lead" lg-margin="0px 0px 40px 0px" color="#626970">
					Ozel Cankurtaran Sürüş Okulu, sürücü eğitiminde mükemmellik konusunda ün kazanmıştır. Deneyimli eğitmenlerimiz, her öğrencinin kendine özgü öğrenme tarzına hitap etmek için modern öğretim yöntemleri kullanmaktadır. Kurslarımız, ömür boyu güvenli sürüş için gerekli bilgi ve becerileri kazanmanızı sağlamak üzere titizlikle tasarlanmıştır.
					</Text>
					<Image
						src="https://crystallineoasis.com/img/2.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
						Neden Ozel Cankurtaran Sürüş Okulunu Seçmelisiniz?
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Profesyonel Eğitmenler: Sertifikalı eğitmenlerden oluşan ekibimiz, zengin bir deneyim ve öğretme tutkusuna sahiptir. Kendilerini sürüş hedeflerinize ulaşmanıza yardımcı olmak için kişiselleştirilmiş eğitim vermeye adamışlardır.

						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Kapsamlı Eğitim: Temel bilgilerden ileri sürüş tekniklerine kadar müfredatımız her şeyi kapsar. Sizi gerçek dünyadaki sürüş koşullarına hazırlayan birinci sınıf eğitim sunmak için en yeni araç ve teknolojileri kullanıyoruz.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Esnek Sınıf Programları: Yoğun hayatınıza uyum sağlamak için çeşitli ders programları sunuyoruz. İster hafta sonu derslerine, ister akşam seanslarına veya özel bir programa ihtiyacınız olsun, sizin için uygun seçeneklerimiz var.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 70px 0px" lg-margin="0px 0px 40px 0px">
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Modern Eğitim Araçları: Eğitim araçlarından oluşan filomuz en yeni güvenlik özellikleriyle donatılmıştır. Araçlarımızın bakımlı ve güncel olmasını sağlayarak güvenli ve konforlu bir öğrenme ortamı sağlıyoruz.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});